module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Henzzo Portfolio","short_name":"henzzo.com","start_url":"/","background_color":"#02021e","theme_color":"#02021e","display":"minimal-ui","scope":"/","icons":[{"src":"logo(513).svg","type":"image/svg+xml","sizes":"514x546"},{"src":"favicon.ico","type":"image/x-icon","sizes":"241x256"},{"src":"logo(197).png","type":"image/png","sizes":"197x210"},{"src":"logo_194.png","type":"image/png","sizes":"194x194"},{"src":"logo(513).png","type":"image/png","sizes":"514x546"}],"description":"Hi, am henok, a digital designer &amp; \n                      developer based in Addis. I build websites of a different \n                      kind with a design that capture your imagination.","screenshots":[{"src":"thumbnail.jpg","type":"image/jpg","sizes":"608x322"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/projects/","/projects/*","/about/","/blog/","/blog/!*","/contact/","/404/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
